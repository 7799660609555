<template>
  <div style="margin: 20px">
    <el-card>
      <el-form :model="form" v-loading="loading">
        <el-form-item label="Код">
          <el-input v-model="form.code"></el-input>
        </el-form-item>
        <el-form-item label="Описание">
          <el-input v-model="form.description" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="Обозначение">
          <el-input v-model="form.designatio"></el-input>
        </el-form-item>
        <el-form-item label="В чём измеряется">
          <el-select
            v-model="form.type"
            clearable
            style="width: 100%">
            <el-option
              v-for="(item) in units"
              :key="item.id"
              :label="item.title"
              :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="Обязательный">
          <el-checkbox v-model="form.required"/>
        </el-form-item>
        <el-form-item label="Минимум">
          <el-input v-model="form.min" type="number"></el-input>
        </el-form-item>
        <el-form-item label="Максимум">
          <el-input v-model="form.max" type="number"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="save" :disabled="lockButton">{{ isCreate ? 'Создать' : 'Сохранить' }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { getUnitOfMeasurement } from '@/api/catalogs'
import { getSymbolDetail, saveSymbol } from '@/api/api'
export default {
  data () {
    return {
      loading: false,
      lockButton: false,
      isCreate: false,
      units: [],
      form: {
        id: null,
        description: null,
        code: null,
        designatio: null,
        min: null,
        max: null,
        type: null,
        required: false
      }
    }
  },
  mounted () {
    this.loadDicts()
    if (this.$route.name.includes('create')) {
      this.isCreate = true
    } else {
      this.fetchData()
    }
  },
  methods: {
    loadDicts () {
      this.loading = true
      getUnitOfMeasurement().then(response => {
        this.units = response.data.data
        this.loading = false
      })
    },
    fetchData () {
      this.loading = true
      getSymbolDetail(this.$route.params.id).then(response => {
        this.form = response.data
      }).finally(() => {
        this.loading = false
      })
    },
    save () {
      this.lockButton = true
      saveSymbol(this.form).then((response) => {
        this.$message({
          message: 'Успешно сохранено!',
          type: 'success'
        })
        if (this.isCreate) {
          this.$router.push({ name: this.$route.name.replace('create', 'view'), params: { id: response.data.id } })
        }
      }).finally(() => {
        this.lockButton = false
      })
    }
  }
}
</script>

<style scoped>

</style>
